<!--
 * @Descripttion: 
 * @version: 
 * @Author: alex.yang
 * @Date: 2023-02-14 09:00:58
 * @LastEditors: SilentStorm 1668645906@qq.com
 * @LastEditTime: 2023-06-16 15:38:56
-->
<template>
    <div class="visitorList">
        <div class="title-wrap">
            <div class="tag"></div>
            <div class="title">来访录入</div>
        </div>
        <div class="case-wrap">
            <div class="tabs-wrap">
                <el-tabs v-model="editableTabsValue" type="card" @tab-click="handleClick">
                    <el-tab-pane v-for="(item, index) in objectiveList" :key="index" :label="item.label" :name="item.name">
                    </el-tab-pane>
                </el-tabs>
                <div class="btn-wrap">
                    <el-button @click="clickAdd()" style="margin-right: 30px" type="primary" plain>录入</el-button>
                    <!-- <el-button :loading="isDowing" @click="derivedForm" type="warning">导出</el-button> -->
                </div>
            </div>
            <div class="screen-wrap">
                <div class="chage-date">
                    <commonSelect @changeDate="changeDate" @clickTagDate="clickTagDate"></commonSelect>
                </div>
                <div class="screen-item">
                    <div class="si-title">归属人</div>
                    <el-select clearable @change="belongerChange" v-model="belonger" placeholder="请选择归属人">
                        <el-option v-for="item in belongerOptions" :key="item.id" :label="item.username" :value="item.id">
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="table-wrap">
                <el-table ref="multipleTable" :data="tableData" border style="width: 100%" height="615"
                    @selection-change="handleSelectionChange" v-loading="loading">
                    <el-table-column align="center" type="selection" width="55"></el-table-column>
                    <el-table-column align="center" prop="name" label="访客名称"></el-table-column>
                    <el-table-column align="center" prop="mobile" label="联系方式"></el-table-column>
                    <el-table-column align="center" prop="address" label="来自地区"></el-table-column>
                    <el-table-column align="center" prop="visitor_time" label="来访时间"></el-table-column>
                    <el-table-column align="center" prop="ObjectiveName" label="来访事由"></el-table-column>
                    <el-table-column align="center" prop="UserName" label="归属人">
                        <template slot-scope="scope">
                            <div style="color: #999" v-if="scope.row.UserName == ''">暂无</div>
                            <div v-else>{{ scope.row.UserName }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="LastRecordTime" label="最后跟进时间">
                        <template slot-scope="scope">
                            <div style="color: #999" v-if="scope.row.LastRecordTime == ''">未跟进</div>
                            <div v-else>{{ scope.row.LastRecordTime }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" fixed="right" width="250" label="操作">
                        <template slot-scope="scope">
                            <el-button v-if="scope.row.LastRecordTime == ''" size="mini" type="info" plain
                                disabled>跟进记录</el-button>
                            <el-button @click="followRecord(scope.row)" v-else size="mini" type="primary">跟进记录</el-button>
                            <el-button @click="clickEdit(scope.row)" size="mini" type="success">编辑</el-button>
                            <el-button @click="clickDelete(scope.row)" size="mini" type="danger">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pagination-wrap">
                    <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="10"
                        layout="total, prev, pager, next" :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>
        <!-- 跟进记录 -->
        <el-dialog :visible.sync="isShowInfo" :before-close="handleClose" custom-class="dialog-show-wrap"
            :close-on-click-modal="false">
            <div class="title">跟进记录</div>
            <div class="content">
                <el-table :data="recordData" border style="width: 100%" v-loading="recordLoading" height="540">
                    <el-table-column align="center" prop="create_timex" label="时间"></el-table-column>
                    <el-table-column align="center" prop="contents" label="跟进内容"
                        :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column align="center" prop="writeUser.username" label="跟进人"
                        :show-overflow-tooltip="true"></el-table-column>
                </el-table>
                <div class="pagination-wrap">
                    <el-pagination @current-change="recordCurrentChange" :current-page.sync="recordPage" :page-size="10"
                        layout="total, prev, pager, next" :total="recordTotal">
                    </el-pagination>
                </div>
            </div>
        </el-dialog>
        <!-- 添加/编辑 -->
        <el-dialog :before-close="handleCloseAdd" :close-on-click-modal="false" :visible.sync="isAddScenarios"
            custom-class="dialog-show-wrap">
            <div class="title">录入来访记录</div>
            <div class="content-wrap">
                <el-form :model="isAddForm" :rules="rulesAddForm" ref="isAddForm" label-width="100px" class="demo-ruleForm">
                    
                    <el-form-item label="来访时间" prop="visitor_time">
                        <el-date-picker v-model="isAddForm.visitor_time" type="datetime" placeholder="选择日期时间" align="right"
                        value-format="yyyy-MM-dd HH:mm:ss"
                            :picker-options="pickerOptions">
                        </el-date-picker>
                    </el-form-item>

                    <el-form-item label="来访事由" prop="objective_id">
                        <el-select clearable v-model="isAddForm.objective_name" placeholder="请选择事由"
                            @change="selectObjective">
                            <el-option v-for="item in objectiveList" :key="item.name" :label="item.label"
                                :value="item.name">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="姓名" prop="name">
                        <el-input type="text" placeholder="请输入联系人姓名" v-model="isAddForm.name">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="电话" prop="mobile">
                        <el-input type="text" placeholder="请输入联系人电话号码" v-model="isAddForm.mobile">
                        </el-input>
                    </el-form-item>

                    <el-form-item label="省" prop="province">
                        <el-select v-model="isAddForm.province" placeholder="请选择省" @change="selectProvince">
                            <el-option v-for="item in provinceOptions" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="市" prop="city">
                        <el-select clearable v-model="isAddForm.city" placeholder="请选择市" @change="selectCity">
                            <el-option v-for="item in cityOptions" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <div style="display: flex;justify-content: flex-end;">
                        <el-button :loading="btnLoading" type="primary" @click="submitFormAdd">保 存</el-button>
                    </div>
                </el-form>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import commonSelect from "@/components/commonSelect.vue"
import pageMixin from '@/internal/pageMixin'
import { dealdate } from "@/utils/timeCycle";
import common from "@/utils/common.js"
import area from "@/utils/area-data.js"
import axios from "axios";
export default {
    components: {
        commonSelect
    },
    mixins: [pageMixin],
    data() {
        return {
            editId: -1,
            isAddScenarios: false,
            isAddForm: {
                objective_id: '',
                objective_name: '',
                visitor_time: '',
                name: '',
                mobile: '',
                province: '',
                city: ''
            },
            rulesAddForm: {
                visitor_time: [
                    { required: true, message: '请选择来访时间', trigger: 'change' },
                ],
                objective_id: [
                    { required: true, message: '请选择事由', trigger: 'change' },
                ],
                name: [
                    { required: true, message: '请选输入姓名', trigger: 'change' },
                ],
                mobile: [
                    { required: true, message: '请选输入电话', trigger: 'change' },
                ],
                province: [
                    { required: true, message: '请选省', trigger: 'change' },
                ],
                city: [
                    { required: true, message: '请选市', trigger: 'change' },
                ],
            },

            pickerOptions: {
                shortcuts: [{
                    text: '今天',
                    onClick(picker) {
                        picker.$emit('pick', new Date());
                    }
                }, {
                    text: '昨天',
                    onClick(picker) {
                        const date = new Date();
                        date.setTime(date.getTime() - 3600 * 1000 * 24);
                        picker.$emit('pick', date);
                    }
                }, {
                    text: '一周前',
                    onClick(picker) {
                        const date = new Date();
                        date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', date);
                    }
                }]
            },

            province_all: area.areaList.provinceList,
            city_all: area.areaList.cityList,
            provinceOptions: [],
            cityOptions: [],

            pageMixin_index: 'visitorList',
            editableTabsValue: '',
            belongerOptions: [],
            sortOptions: [],
            belonger: '',
            sort: 1,
            objectiveList: [],
            tableData: [],
            objectiveId: '',
            dateType: '',
            startDate: '',
            endDate: '',
            currentPage: 1,
            total: 0,
            loading: false,
            idsList: [],
            isAllocation: false,
            isShowInfo: false,
            userId: '',
            btnLoading: false,
            info: {},
            isDowing: false,
            recordData: [],
            recordPage: 1,
            recordTotal: 0,
            recordLoading: false
        }
    },
    mounted() {
        this.getInit();
        // console.log(this.provinceOptions);
    },
    methods: {
        // 选择省
        selectProvince(e) {
            let p_code = e.substr(0, 2);
            this.isAddForm.city = '';
            this.cityOptions = [];

            this.province_all.map(v => {
                if (e == v.value) {
                    this.isAddForm.province = v.label;
                }
            });

            this.city_all.map(v => {
                let c_code = v.value.substr(0, 2);
                if (p_code == c_code) {
                    this.cityOptions.push(v);
                }
            });

        },
        //选择市
        selectCity(e) {
            this.city_all.map(v => {
                if (e == v.value) {
                    this.isAddForm.city = v.label;
                }
            });
        },
        //选择来访事由
        selectObjective(e) {
            this.objectiveList.map(v => {
                if (e == v.name) {
                    this.isAddForm.objective_name = v.label;
                    this.isAddForm.objective_id = v.name;
                }
            });
        },
        handleCloseAdd() {
            this.isAddScenarios = false;
        },
        //保存提交
        submitFormAdd() {
            this.$refs.isAddForm.validate((valid) => {
                if (valid) {

                    this.btnLoading = true;
                    if (this.editId == -1) {
                        //添加
                        let params = {
                            visitor_time: this.isAddForm.visitor_time,
                            objective_id: this.isAddForm.objective_id,
                            name: this.isAddForm.name,
                            mobile: this.isAddForm.mobile,
                            province: this.isAddForm.province,
                            city: this.isAddForm.city,
                            address: this.isAddForm.province + this.isAddForm.city,
                        }
                        common.connect('/distributorpcv1/Visitor/setAddVisitor', params, (res) => {
                            if (res.isSuccess == 1) {
                                this.isAddScenarios = false;
                                this.btnLoading = false
                                this.$refs.isAddForm.resetFields();
                                this.getVisitorList();
                            } else {
                                this.$message.error(res.msg);
                                this.btnLoading = false
                            }
                        })
                    } else {
                        //编辑
                        let params = {
                            id: this.editId,
                            visitor_time: this.isAddForm.visitor_time,
                            objective_id: this.isAddForm.objective_id,
                            name: this.isAddForm.name,
                            mobile: this.isAddForm.mobile,
                            province: this.isAddForm.province,
                            city: this.isAddForm.city,
                            address: this.isAddForm.province + this.isAddForm.city,
                        }
                        common.connect('/distributorpcv1/Visitor/setAddVisitorEdit', params, (res) => {
                            if (res.isSuccess == 1) {
                                this.isAddScenarios = false;
                                this.btnLoading = false
                                this.$refs.isAddForm.resetFields();
                                this.getVisitorList();
                            } else {
                                this.$message.error(res.msg);
                                this.btnLoading = false
                            }
                        })
                    }

                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        // 编辑按钮
        clickEdit(row) {
            console.log(row);
            this.editId = row.id;
            this.isAddForm = {
                objective_id: row.objective_id,
                visitor_time: row.visitor_time,
                name: row.name,
                mobile: row.mobile,
                province: row.province,
                city: row.city,
            }

            this.objectiveList.map(v => {
                if (row.objective_id == v.name) {
                    this.isAddForm.objective_name = v.label;
                }
            });

            this.isAddScenarios = true
        },
        // 添加按钮
        clickAdd() {
            this.editId = -1;
            this.isAddForm = {
                objective_id: '',
                objective_name: '',
                visitor_time: '',
                name: '',
                mobile: '',
                province: '',
                city: '',
            }
            this.isAddScenarios = true
        },
        // 删除
        clickDelete(row) {
            this.$confirm('此操作将永久删除该联系人, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                common.connect('/distributorpcv1/Visitor/setAddVisitorDel', { id: row.id }, (res) => {
                    this.getVisitorList();
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        // 获取列表数据
        getVisitorRecordList() {
            this.recordLoading = true;
            let params = {
                visitor_id: this.info.id,
            }
            common.connect('/distributorpcv1/visitor/getVisitorRecordList', params, (res) => {
                this.recordData = res.data.list;
                this.recordTotal = res.data.count;
                this.recordLoading = false;
            })
        },
        recordCurrentChange(val) {
            this.recordPage = val;
            this.getVisitorRecordList();
        },
        // 导出
        async derivedForm() {
            let token = localStorage.getItem("token");
            var authorization = 'Bearer ' + token
            if (this.tableData.length == 0) {
                this.$message.warning('暂无数据可以导出');
                return
            }
            this.isDowing = true;
            let params = {
                date_type: this.dateType,
                start_date: this.startDate,
                end_date: this.endDate,
                objective_id: this.objectiveId,
                uid: this.belonger,
            }
            let url = common.apiServer + '/distributorpcv1/Visitor/export'
            axios({
                url: url,
                responseType: "blob",
                method: 'POST',
                data: params,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authorization,
                }
            }).then(function (res) {
                let day = dealdate(new Date()).slice(0, 10)
                const blob = new Blob([res.data]);
                const fileName = '来访列表数据' + day + '.xls';
                const linkNode = document.createElement('a');
                linkNode.download = fileName; //a标签的download属性规定下载文件的名称
                linkNode.style.display = 'none';
                linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
                document.body.appendChild(linkNode);
                linkNode.click();  //模拟在按钮上的一次鼠标单击
                URL.revokeObjectURL(linkNode.href); // 释放URL 对象
                document.body.removeChild(linkNode);
            })
            setTimeout(() => {
                this.isDowing = false;
            }, 2000)
        },
        // 跟进记录
        followRecord(row) {
            this.isShowInfo = true;
            this.info = row;
            this.getVisitorRecordList();
        },
        handleClose() {
            this.isAllocation = false;
            this.isShowInfo = false;
        },
        // 多选
        handleSelectionChange(val) {
            this.idsList = val;
        },
        // 获取列表数据
        getVisitorList() {
            this.loading = true;
            let params = {
                date_type: this.dateType,
                start_date: this.startDate,
                end_date: this.endDate,
                objective_id: this.objectiveId,
                order: this.sort,
                uid: this.belonger,
                page: this.currentPage
            }
            common.connect('/distributorpcv1/visitor/getAddList', params, (res) => {
                this.tableData = res.data.list;
                this.total = res.data.count;
                this.loading = false;
            })
        },
        // 初始化
        getInit() {
            let that = this;

            this.provinceOptions = this.province_all;
            // this.cityOptions = this.city_all;

            common.connect('/distributorpcv1/Visitor/getAddInit', {}, (res) => {
                // console.log(res.data.objective_list);

                this.belongerOptions = res.data.u_list;
                this.sortOptions = res.data.order_list;
                let resultSecond = res.data.objective_list.map(v => {
                    return {
                        label: v.name,
                        name: "" + v.id
                    }
                })
                this.objectiveList = resultSecond;

                this.objectiveId = res.data.objective_list[0].id;
                that.editableTabsValue = resultSecond[0].name;

                // console.log(this.objectiveId);
                this.getVisitorList();
            })


        },
        handleClick(tab, event) {
            console.log(tab);
            this.objectiveId = tab.name;
            this.currentPage = 1;
            this.getVisitorList();
        },
        sortChange() {
            this.currentPage = 1;
            this.getVisitorList();
        },
        // 归属人筛选
        belongerChange() {
            this.currentPage = 1;
            this.getVisitorList();
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getVisitorList();
        },
        // 日期选择
        changeDate(startDate, endDate) {
            this.currentPage = 1;
            this.startDate = startDate.slice(0, 11) + '00:00:01';
            this.endDate = endDate.slice(0, 11) + '23:59:59';
            this.getVisitorList()
        },
        clickTagDate(item) {
            this.dateType = item.label;
            this.currentPage = 1;
            if (item.label != 'date') {
                this.startDate = '';
                this.endDate = '';
                this.getVisitorList();
            }
        },
    },
}
</script>

<style lang='scss'>
.visitorList {
    background: rgb(240, 242, 245);
    padding: 10px 25px;
    height: calc(100vh - 80px);
    overflow-y: auto;

    .title-wrap {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 5px 0;

        .tag {
            width: 4px;
            height: 20px;
            background: #409EFF;
            border-radius: 10px;
        }

        .title {
            font-size: 18px;
            font-weight: bold;
            padding-left: 10px;
        }
    }

    .case-wrap {
        background: #fff;
        margin-top: 20px;
        border-radius: 6px;
        position: relative;

        .el-tabs__header {
            padding-top: 20px;
            margin: 0 20px;
        }

        .btn-wrap {
            position: absolute;
            right: 20px;
            top: 15px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }

        .screen-wrap {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px;

            .screen-item {
                display: flex;
                flex-wrap: wrap;
                align-items: center;

                .si-title {
                    font-size: 14px;
                    padding-right: 10px;
                }
            }
        }

        .table-wrap {
            padding: 0 20px 0px 20px;

            .pagination-wrap {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                padding-top: 6px;
            }
        }
    }

    // 迁移
    .dialog-wrap {
        width: 500px;

        .title {
            display: flex;
            justify-content: center;
            font-size: 17px;
            color: #000;
            font-weight: bold;
            padding-top: 20px;
        }

        .content {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-left: 20px;
            padding: 30px 0;

            .tag {
                margin-right: 10px;
            }

            .el-input {
                width: 390px;
            }
        }

        .btn-wrap {
            display: flex;
            justify-content: flex-end;
            margin-right: 25px;
            padding-bottom: 10px;
        }
    }

    .dialog-show-wrap {
        width: 700px;
        overflow: hidden;
        padding: 20px;

        .title {
            font-size: 17px;
            color: #000;
            font-weight: bold;
            margin-top: -30px;
        }

        .content {
            padding-top: 20px;

            .c-item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 10px 0;

                .tit {
                    width: 60px;
                    color: #000;
                    font-size: 15px;
                    text-align: justify;
                    text-align-last: justify;
                }

                .con {
                    width: calc(100% - 80px);
                    color: #666;
                    font-size: 15px;
                }
            }

            .pagination-wrap {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                padding-top: 6px;
            }
        }
    }

    .el-dialog {
        margin: 0;
        margin-top: 0vh !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding-bottom: 10px;
    }
}
</style>
